"use client";
import { login } from "@/app/auth/login/actions";
import { useFormState } from "react-dom";
import LoginFormInputs from "./LoginFormInputs";

const initalState = {
  errors: {},
};

const LoginForm = () => {
  const [state, formAction] = useFormState(login, initalState);

  return (
    <form className="card-body" action={formAction}>
      <LoginFormInputs state={state} />
    </form>
  );
};

export default LoginForm;
